import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from "@components/SEO"
import {
  SignupBannerWrapper,
  mapToKiwiVIPVisible,
} from "@components/SignupBanner"
import {
  CustomerReviewCardSlider,
  mapToCustomerReviewData,
} from "@components/CustomerReviewCardSlider"
import {
  ImageInformationBanner,
  mapToImageInfoBanner,
} from "@components/ImageInformationBanner"
import {
  mapToInfoImageSlider,
  InfoImageSlider,
} from "@components/InfoImageSlider"
import {
  ImageInformationList,
  mapToInfoBannerContainer,
  mapToInfoBannerContainerWithTitle,
} from "@components/ImageInformationList"
import {
  mapToImageLinkBanner,
  ImageButtonBanner,
} from "@components/ImageButtonBanner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"

const AboutUs = (props: any) => {
  const { elements } = props.data.kontentItemAboutUsPage
  const seo = mapToSEO(elements)
  const kiwiVip = mapToKiwiVIPVisible(elements)
  const metadata = mapToPageHeader(elements)
  const imageInfoTop = mapToImageInfoBanner(
    elements.info_image_banner___top.linked_items[0].elements
  )
  const imageSliders = mapToInfoImageSlider(
    elements.slider_information_images.linked_items
  )
  const imageInfoBottom = mapToImageInfoBanner(
    elements.info_image_banner___bottom.linked_items[0].elements
  )
  const customerReviewData = mapToCustomerReviewData(
    elements.customer_reviews.linked_items[0]?.elements, true
  )
  const aboutUsLinkCards = mapToInfoBannerContainerWithTitle(
    elements.about_us_link_cards.linked_items[0]
  )

  const infoBanner = mapToInfoBannerContainer(
    elements.info_banner_list.linked_items[0].elements
  )
  const imageLink = mapToImageLinkBanner(
    elements.image_link.linked_items[0]?.elements
  )
  return (
    <Layout location={props.location}>
      <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />
      {imageInfoTop && <GenericBannerContainer
        padding={{
          mobile: {
            top: 80,
            bottom: 60,
          },
          desktop: {
            top: 100,
            bottom: 80,
          },
        }}
        backgroundColor={imageInfoBottom?.backgroundColor}
      >
        <ImageInformationBanner verticalPosition="middle" {...imageInfoTop} />
      </GenericBannerContainer>}
      {imageSliders && <InfoImageSlider {...imageSliders} />}
      {aboutUsLinkCards && <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80,
          },
          desktop: {
            top: 80,
            bottom: 100,
          },
        }}
        backgroundColor={aboutUsLinkCards.backgroundColor}
      >
        <ImageInformationList
          listTitle={aboutUsLinkCards.listTitle}
          listItems={aboutUsLinkCards.listItems}
          backgroundColor={aboutUsLinkCards.backgroundColor}
          columns={3}
          horizontalLayout={false}
          titleOverlayImage={true}
          titleColor="white"
          textColor="black"
        />
      </GenericBannerContainer>}
      {imageInfoBottom && <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 60,
          },
          desktop: {
            top: 80,
            bottom: 80,
          },
        }}
        backgroundColor={imageInfoBottom.backgroundColor}
      >
        <ImageInformationBanner
          verticalPosition="middle"
          {...imageInfoBottom}
        />
      </GenericBannerContainer>}
      {customerReviewData && <CustomerReviewCardSlider {...customerReviewData} />}
      {infoBanner && <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80,
          },
          desktop: {
            top: 80,
            bottom: 100,
          },
        }}
        backgroundColor={infoBanner.backgroundColor}
      >
        <ImageInformationList
          listItems={infoBanner.listItems}
          backgroundColor={infoBanner.backgroundColor}
          columns={4}
          horizontalLayout={false}
          titleOverlayImage={false}
          titleColor="white"
          textColor="white"
        />
      </GenericBannerContainer>}
      {imageLink && <ImageButtonBanner {...imageLink} />}
      {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemAboutUsPage(elements: {url: {value: {eq: "/about-us"}}}) {
      elements {
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position {
          value {
            codename
          }
        }
        url {
          value
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        info_image_banner___top {
          linked_items {
            ... on KontentItemInfoImageBanner {
              elements {
                background_colors {
                  value {
                    codename
                  }
                }
                text_colors {
                  value {
                    codename
                  }
                }
                title {
                  value
                }
                description {
                  value
                }
                image {
                  value {
                    url
                    description
                  }
                }
                info_image_banner___image_position {
                  value {
                    codename
                  }
                }
              }
            }
          }
        }
        about_us_link_cards {
          linked_items {
            ... on KontentItemInfoBannerContainer {
              system {
                name
              }
              elements {
                background_colors {
                  value {
                    codename
                  }
                }
                text_colors {
                  value {
                    codename
                  }
                }
                text_alignment {
                  value {
                    codename
                  }
                }
                items {
                  linked_items {
                    ... on KontentItemInfoBannerItem {
                      elements {
                        icon {
                          value {
                            url
                          }
                        }
                        title {
                          value
                        }
                        description {
                          value
                        }
                        linkurl {
                          value
                        }
                        linktext {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        slider_information_images {
          linked_items {
            ... on KontentItemInfoImageSliderItem {
              elements {
                name {
                  value
                }
                image {
                  value {
                    url
                  }
                }
                description {
                  value
                }
                button__text {
                  value
                }
                button__button_type {
                  value {
                    codename
                  }
                }
                button__button_color_theme {
                  value {
                    codename
                  }
                }
                button__url_slug {
                  value
                }
              }
            }
          }
        }
        info_image_banner___bottom {
          linked_items {
            ... on KontentItemInfoImageBanner {
              elements {
                background_colors {
                  value {
                    codename
                  }
                }
                text_colors {
                  value {
                    codename
                  }
                }
                title {
                  value
                }
                description {
                  value
                }
                image {
                  value {
                    url
                    description
                  }
                }
                info_image_banner___image_position {
                  value {
                    codename
                  }
                }
              }
            }
          }
        }
        info_banner_list {
          linked_items {
            ... on KontentItemInfoBannerContainer {
              elements {
                background_colors {
                  value {
                    codename
                  }
                }
                text_colors {
                  value {
                    codename
                  }
                }
                text_alignment {
                  value {
                    codename
                  }
                }
                items {
                  linked_items {
                    ... on KontentItemInfoBannerItem {
                      elements {
                        icon {
                          value {
                            url
                          }
                        }
                        title {
                          value
                        }
                        description {
                          value
                        }
                        linktext {
                          value
                        }
                        linkurl {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        image_link {
          linked_items {
            ... on KontentItemImageLinkBanner {
              elements {
                image {
                  value {
                    url
                  }
                }
                title {
                  value
                }
                button__text {
                  value
                }
                button__button_type {
                  value {
                    codename
                  }
                }
                button__button_color_theme {
                  value {
                    codename
                  }
                }
                button__url_slug {
                  value
                }
              }
            }
          }
        }
        customer_reviews {
          linked_items {
            ... on KontentItemCustomerReviewCardBanner {
              elements {
                title {
                  value
                }
                subtitle{
                  value
                }
                title_2{
                  value
                }
                rating {
                  value
                }
                totalreviews {
                  value
                }
                background_colors {
                  value {
                    codename
                  }
                }
                cards {
                  linked_items {
                    ... on KontentItemCustomerReviewCard {
                      elements {
                        customer_name {
                          value
                        }
                        avatar {
                          value {
                            url
                          }
                        }
                        rating {
                          value
                        }
                        title {
                          value
                        }
                        description {
                          value
                        }
                        create_date{
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default AboutUs
