import React from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import {SliderImageBannerProps} from './Types'
import InfoImageSliderItem from './InfoImageSliderItem'

interface InfoImageSliderProp {
  sliders: SliderImageBannerProps[]
}

const InfoImageSlider = (props: InfoImageSliderProp) => {
  const {sliders} = props;
    var settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'react-slider',
      dotsClass: 'default-slick-dots'
    };
    return (
      <Slider {...settings}>
        {
          sliders.map((data,key)=>{
            return <InfoImageSliderItem key={key} image={data.image} content={data.content} username={data.username} button={data.button} />
          })
        }
      </Slider>
    );
  
}

export default InfoImageSlider