import {convertKontentColorToHex} from '@utils/Helpers'
import { KenticoImage, KenticoChoice } from '@utils/KontentTypes';

interface CustomerReviewDataProps{
    title: {
        value: string
    }
    subtitle: {
        value: string
    }
    title_2: {
        value: string
    }
    background_colors: {
        value: KenticoChoice[]
    }
    cards: {
        linked_items: Array<{elements: CustomerReviewCardProps}>
    }
    rating:{
        value: number
    }
    totalreviews:{
        value: number
    }
    hasGoogleReview: boolean
}

export const mapToCustomerReviewData = (data: CustomerReviewDataProps | undefined, hasGoogleReview: boolean) =>{
    
    if(data == undefined)
        return undefined;
    const {title, background_colors,cards,rating,totalreviews, title_2, subtitle} = data;
    const cardData = cards.linked_items.map(card=>mapToCustomerReviewCard(card.elements));
    return{
        title: title.value,
        title_2: title_2.value,
        subtitle: subtitle.value,        
        backgroundColor: convertKontentColorToHex(background_colors.value[0].codename),
        sliders: cardData,
        rating: rating.value,
        totalReviews: totalreviews.value,
        hasGoogleReview: hasGoogleReview
    }
}

interface CustomerReviewCardProps{
        customer_name:{
            value: string
        }
        avatar:{
            value: KenticoImage[]
        }
        rating: {
            value: number
        }
        title:{
            value: string
        }
        description:{
            value: string
        }
        create_date: {
            value: string
        }
}

export const mapToCustomerReviewCard = (card: CustomerReviewCardProps) =>{
    const {customer_name,avatar,rating,title,description, create_date} = card;

    return{
        avatarUrl: avatar.value && avatar.value.length > 0 ? avatar.value[0].url : '',
        username: customer_name.value,
        rating: rating.value,
        title: title.value,
        create_date: create_date.value,
        content: description.value,
        hasGoogleReview: true
    }
}